<template>
    <div id="general-content-header" class="py-2">
        <div class="row mt-2 mb-4 align-items-center">
            <div class="col-md-4 ">
                <div class="row mb-md-4">
                    <div class="col-md-12">
                        <div class="sidebar_toggler">
                            <img src="../../assets/icons/menu.svg" class="img-fluid sidebar_icon float-md-left float-right" v-b-toggle.main_sidebar alt="menu">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="content_title">
                            <p class="subcat_content_title text-white text-uppercase">{{category}}</p>
                            <div class="sub_cat_detail_group">
                                <div class="sub_cat_title text-white">
                                    <img class="sub_cat_title_icon" src="../../assets/icons/arrow-white.svg" alt="sub_cat_title_icon">
                                    <span class="link" @click="$router.push({name: 'sub-category', params: {id: $route.params.subcat_id, tab_index: $route.params.tab_index}})">{{subCategory}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 text-center">
                <router-link :to="{name: 'home'}">
                    <img class="subcat_content_logo" src="../../assets/logos/Al-haq-Logo-selected.svg" alt="">
                </router-link>
            </div>
            <div class="col-md-4">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            category: String,
            subCategory: String
        }
    }
</script>

<style scoped>
    .sidebar_icon {
        width: 40px !important;
        height: 40px !important;
        cursor:pointer !important;
    }
    .sidebar_icon:focus {
        outline: none !important;
    }
    .subcat_content_logo {
        width: 180px !important;
        height: 180px !important;
    }
    .subcat_content_title {
        font-size: 26px !important;
        font-family: brandFont-bold;
        width: fit-content;
    }
    .subcat_content_title::before {
        font-size: 26px;
        content: '';
        margin-right: 15px;
        font-family: brandFont-bold;
        border-left: 8px solid #28abe1;
    }
    .subcat_content_title::after {
        content: ' ';
        margin-top: 5px;
        display: block;
        border: .5px solid white;
    }

    .sub_cat_title {
        color: #28abe1 !important;
        font-family: brandFont-subcat;
        font-size: 22px !important;
    }

    .sub_cat_title_icon {
        height: 22px !important;
        width: 22px !important;
    }
</style>