<template>
    <div id="listen-audio-view">
        <l-aud></l-aud>
    </div>
</template>

<script>
    import listenAudio from '../components/public/listen-audio'
    export default  {
        components: {
            'l-aud': listenAudio
        }
    }


</script>


<style scoped>


</style>