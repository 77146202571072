<template>
    <div id="listen-audio" class="py-2 px-5">
        {{$route.params}}
        <Loader :state="loader_state"></Loader>
        <g-content-header :category="category_name" :sub-category="audioc_data.subcategory_details ? audioc_data.subcategory_details.name : ''"></g-content-header>
        <div class="row">
            <div class="col-md-8 text-left">
                <p class="pl_title my-1">{{audioc_data.title}}</p>
                <p class="pl_speaker my-1">{{audioc_data.speaker_details ? audioc_data.speaker_details.name : ''}}</p>
            </div>
            <div class="col-md-4 text-md-right text-left my-md-0 my-4">
                <ShareNetwork
                v-for="network in networks"
                :network="network.network"
                :key="network.key"
                :title="audioc_data.title ? audioc_data.title : ''"
                :description="audioc_data.description"
                :url="shareURL+$route.path">
                <font-awesome-icon class="text-white h3 mx-3 sharing" :icon="['fab', network.icon]"/>
            </ShareNetwork>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-4">
                <p class="playlist_detail my-1">Total Videos: <span class="playlist_detail_value">{{ audioc_data.audios ? audioc_data.audios.length : 0 }}</span></p>
                <p class="playlist_detail my-1">Status: <span class="playlist_detail_value">{{audioc_data.status ? 'Completed' : 'InProgress'}}</span></p>
            </div>
            <div class="col-md-4">
                <p class="playlist_detail my-1">Language: <span class="playlist_detail_value">{{ audioc_data.language_details ? audioc_data.language_details.name : '' }}</span></p>
                <p class="playlist_detail my-1">Source: <span class="playlist_detail_value">{{audioc_data.source ? audioc_data.source : 'N/A' }}</span></p>
            </div>
        </div>
        <div class="row mb-5 mt-4">
            <div class="col-md-12">
                <p class="pl_desc">{{audioc_data.description}}</p>
            </div>
        </div>
        <div class="row audio_row px-2 mb-5 py-4 align-items-center" v-for="(item, index) in audioc_data.audios" :key="index">
            <div class="col-md-12">
                <p class="audio_title">{{item.title}}</p>
                <audio :id="index" controls class="w-100 audio_player">
                    <source :src="baseURL+item.audio">
                </audio>
            </div>
        </div>

    </div>

</template>

<script>
    import gContentHeader from './general-content-header'
    import {mapActions , mapGetters} from 'vuex'
    import Loader from '../shared/alhaq_loader'
    import config from "../../assets/config";
    export default {
        components: {
            'g-content-header': gContentHeader,
            "Loader" : Loader
        },
        data () {
            return {
                networks: [
                    { network: 'twitter', name: 'Twitter', icon: 'twitter', color: '#1da1f2' },
                    { network: 'whatsapp', name: 'Whatsapp', icon: 'whatsapp', color: '#25d366' },
                    { network: 'skype', name: 'Skype', icon: 'skype', color: '#00aff0' },
                ],
                loader_state : false,
                baseURL: config.baseURL,
                shareURL: config.shareURL,
                audioc_data: [],
                category_name: ''
            }
        },
        methods: {
            playAudio(index) {
                document.getElementById(index).play();
            },
            ...mapActions([
                'getAudioCollectionById',
                'getCategoryById'
            ])
        },
        watch: {
            'this.$route.params.audioc_id': {
                handler: function () {
                    this.loader_state = true
                    this.getAudioCollectionById({'id': this.$route.params.audioc_id, 'contentDetails': true}).then(response => {
                        this.audioc_data = response.data.data
                    }).then(()=> {
                        this.getCategoryById({'id': this.audioc_data.subcategory_details.category_id}).then(response => {
                            this.category_name = response.data.data.name;
                            this.loader_state = false
                        })
                    })
                },
                immediate: true
            }
        }
    }

</script>

<style scoped>
    .pl_title {
        font-family: brandFont-bold;
        font-size: 26px;
        color: #28abe1;
        word-break: break-word;
    }
    .pl_speaker {
        font-size: 18px !important;
        color: #ffffff;
        font-family: brandFont-subcat;
    }
    .pl_desc {
        font-size: 14px !important;
        color: #ffffff;
        font-family: brandFont-subcat;
    }
    .pl_speaker {
        color: #28abe1 !important;
    }
    .playlist_detail {
        font-family: brandFont-subcat;
        color: #ffffff;
        font-size: 14px;
    }
    .playlist_detail_value {
        font-family: brandFont-cat;
        color: #28abe1;
        font-size: 14px;
    }
    .audio_download_btn {
        outline: none;
        color: black;
        background-color: #ffffff !important;
        box-shadow: 0px 4px 4px 3px #330d0ded;
        font-size: 16px;
        font-family: brandFont !important;
        transition: box-shadow .2s ease-in-out;
    }
    .audio_row {
        border: 0.5px dashed white;
    }
    .audio_title {
        font-family: brandFont-bold;
        font-size: 16px;
        color: #28abe1;
    }
    .audio_player {
        max-height: 30px;
    }
    .sharing {
        cursor: pointer;
    }
    .sharing:hover {
        color: #28abe1 !important;
    }
</style>